
import { computed, onMounted, watch } from "vue";
import mediaHelper from "@libraryHelpers/media.js";
import { useIsExpired } from "@libraryComposables/useIsExpired";
import { useMedia } from "@libraryComposables/useMedia";
import { useReducedMotion } from "@libraryComposables/useReducedMotion";
import { useStore } from "@libraryHelpers/compositionApi";

export default {
	name: "CoreBlockVideo",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const store = useStore();
		const { state } = store;
		const jwplayerLibraryUrl =
			"https://content.jwplatform.com/libraries/ljXVSIQY.js";
		const htmlVideoId = "jwplayer-script-video";
		const { isExpired } = useIsExpired(
			props.settings,
			(item) => item.type === props.settings.type
		);
		const mediaSrc = computed(() => props.settings?.src);
		const {
			mediaUrl: src,
			isBlobFile: isFile,
			isEMoney: isEMoneyUrl,
			videoAutoPlay,
			videoLoop,
		} = useMedia(mediaSrc);
		const { prefersReducedMotion } = useReducedMotion();

		const fullSrc = computed(() => {
			if (!src.value) return;
			if (!state?.settings?.featureFlags?.VideoAutoPlay) return src.value;
			if (prefersReducedMotion.value) return src.value;

			let draftSrc = src.value;
			if (props.settings.autoPlay)
				draftSrc = `${draftSrc}${videoAutoPlay.value}`;
			if (props.settings.loop) draftSrc = `${draftSrc}${videoLoop.value}`;

			return draftSrc;
		});

		const playerId = computed(() => {
			if (isEMoneyUrl.value) {
				return mediaHelper.checkIfHasPlayerId(src.value);
			}
			return "";
		});

		if (props.isEditable) {
			watch(isEMoneyUrl, async (newVal) => {
				if (newVal && newVal.length > 0) {
					await loadScript(jwplayerLibraryUrl);
					loadPlayer();
				} else {
					removeLoadedVideoScript();
				}
			});
		}
		onMounted(async () => {
			if (isEMoneyUrl.value && isEMoneyUrl.value.length > 0) {
				await loadScript(jwplayerLibraryUrl);
				loadPlayer();
			}
		});

		function loadScript(src) {
			return new Promise((resolve) => {
				const scriptExists = document.querySelector(
					`script[src="${src}"]`
				);
				if (scriptExists) {
					resolve(true);
					return;
				}

				const script = document.createElement("script");
				script.src = src;
				script.onload = () => resolve(true);
				document.body.appendChild(script);
			});
		}

		function loadPlayer() {
			if (!src.value) {
				return;
			}

			const existingPlayerScript = document.getElementById(htmlVideoId);
			if (existingPlayerScript) {
				removeLoadedVideoScript();
			}

			const script = document.createElement("script");
			script.id = htmlVideoId;
			script.src = src.value;
			document.head.appendChild(script);
		}

		function removeLoadedVideoScript() {
			const existingVideoScript = document.getElementById(htmlVideoId);
			if (existingVideoScript) {
				existingVideoScript.parentNode.removeChild(existingVideoScript);
			}
		}

		return {
			isExpired,
			src,
			fullSrc,
			isFile,
			isEMoneyUrl,
			playerId,
		};
	},
};
