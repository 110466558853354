
import { ref, computed } from "vue";
import { useIsExpired } from "@libraryComposables/useIsExpired";
import { useImageProperties } from "@libraryComposables/useImageProperties";
import { useMedia } from "@libraryComposables/useMedia";
import CoreBlockVideo from "./CoreBlockVideo.vue";

export default {
	name: "CoreBlockImage",
	components: { CoreBlockVideo },
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const imageComponent = ref(null);

		const {
			imageSrc,
			imageAlt,
			croppedSrc,
			imageHeight,
			imageWidth,
			mediaBackgroundPosition,
			backgroundMediaFocal,
			backgroundMediaCrop,
			updateCroppedImage,
		} = useImageProperties(props, "src", imageComponent);

		const mediaSrc = computed(() => props.settings?.src);
		const { isVideo } = useMedia(mediaSrc);

		const { isExpired } = useIsExpired(
			props.settings,
			(item) => item.type === props.settings.type
		);

		const loadImage = (image) => {
			const src = image.getAttribute("data-src");
			image.src = src;
			image.removeAttribute("data-src");
		};

		return {
			isExpired,
			imageAlt,
			imageSrc,
			croppedSrc,
			imageHeight,
			imageWidth,
			mediaBackgroundPosition,
			backgroundMediaFocal,
			backgroundMediaCrop,
			isVideo,
			loadImage,
			updateCroppedImage,
			imageComponent,
		};
	},
};
