
export default {
	name: "CoreBlockLazy",
	props: {
		onLoad: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			observerOptions: {
				threshold: 0.1,
			},
			observer: null,
		};
	},
	mounted() {
		this.initializeObserver();
	},
	methods: {
		initializeObserver() {
			if (this.observer) {
				return;
			}

			this.observer = new IntersectionObserver((entries, observer) => {
				entries.forEach((entry) => {
					if (!entry.isIntersecting) {
						return;
					}
					this.onLoad(entry.target.children);
					observer.unobserve(entry.target);
				});
			}, this.observerOptions);

			this.observer.observe(this.$refs.lazyComponent);
		},
		destroyObserver() {
			this.observer.disconnect();
			this.observer = null;
		},
	},
	onDestroy() {
		this.destroyObserver();
	},
};
