export default {
	isImage(src) {
		return (
			src &&
			src.match(
				/\.jpg|\.jpeg|\.jfif|\.pjpeg|\.pjp|\.gif|\.png|\.svg|\.webp|^data:image\//i
			)
		);
	},
	isFile(src) {
		return src && src.match(/\.csv|\.pdf|\.html|\.txt\//i);
	},
	isVideo(src) {
		return (
			src &&
			(this.isYoutube(src) ||
				this.isVimeo(src) ||
				this.isBrightcove(src) ||
				this.isEMoney(src))
		);
	},
	isYoutube(src) {
		return src.match(/https:\/\/www\.youtube\.com\/embed\/.*/);
	},
	isVimeo(src) {
		return src.match(/https:\/\/player\.vimeo\.com\/video\/.*/);
	},
	isBrightcove(src) {
		return src.match(/https:\/\/players\.brightcove\.net\/.*/);
	},
	isEMoney(src) {
		return src.match(/https:\/\/abm\.emaplan\.com\/.*/);
	},
	loadImage(src) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = src;
			img.onload = () => resolve(img);
			img.onerror = (e) => reject(e);
		});
	},
	async getImageBackgroundPosition(
		metaData,
		focalPointMedatada,
		croppedCoordinates
	) {
		try {
			const img = metaData?.image || (await this.loadImage(metaData.url));

			//get coordinates for the central point
			let x =
				(focalPointMedatada?.left /
					(croppedCoordinates?.width || img?.width)) *
				100;
			let y =
				(focalPointMedatada?.top /
					(croppedCoordinates?.height || img?.height)) *
				100;
			x = x > 100 ? 100 : x;
			y = y > 100 ? 100 : y;

			x = focalPointMedatada?.flip?.horizontal ? 100 - x : x;
			y = focalPointMedatada?.flip?.vertical ? 100 - y : y;

			if (isNaN(x) || isNaN(y)) return "50% 50%";

			return `${Math.round(x)}% ${Math.round(y)}%`;
		} catch {
			return "50% 50%";
		}
	},
	getEncodedURI(url) {
		const isEncoded = decodeURI(url) !== url;
		return isEncoded
			? url
			: encodeURI(url).replace(/\(/g, "%28").replace(/\)/g, "%29");
	},
	checkIfHasPlayerId(url) {
		if (!url) return "";
		let myUrl = new URL(url);
		let params = myUrl.searchParams;
		if (params.has("playerId")) {
			return params.get("playerId");
		}
		return "";
	},
	getMediaTypeFromSrc(src) {
		if (!src) return "unknown";
		const _src = src.toLowerCase();

		if (this.isImage(src)) return "image";
		if (this.isVideo(src)) return "video"; // External video

		if (
			_src.includes("mp4") ||
			_src.includes("mov") ||
			_src.includes("avi") ||
			_src.includes("webm")
		)
			return "video"; // Uploaded video

		return "unknown";
	},
};
