import { onMounted, onUnmounted, ref } from "vue";

export function useReducedMotion() {
	const prefersReducedMotion = ref(false);
	let mediaQuery = null;

	onMounted(() => {
		mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
		if (mediaQuery) {
			prefersReducedMotion.value = mediaQuery.matches;
			mediaQuery.addEventListener("change", handleReducedMotionChange);
		}
	});

	onUnmounted(() => {
		if (mediaQuery)
			mediaQuery.removeEventListener("change", handleReducedMotionChange);
	});

	function handleReducedMotionChange(event) {
		prefersReducedMotion.value = event.matches;
	}

	return { prefersReducedMotion };
}
